import { env, ROUTE_NAV } from '@/shared/config'

import { paymentInvoicePost } from '../api/paymentInvoicePost.api'

export function invoiceCreateInApp(bundle: string, description: string) {
  const callbackUrl = `${env.FRONT_URL}/app/payment-check/?id=:id`
  return paymentInvoicePost(bundle, description, callbackUrl)
}

export function invoiceCreateInOnboarding(bundle: string, description: string) {
  const callbackUrl = `${env.FRONT_URL}${ROUTE_NAV.onboardingFemalePayment}?check=true`
  return paymentInvoicePost(bundle, description, callbackUrl)
}
