type ApiResponseInvoice = {
  id: string
  type: string
  bundle: string
  status: string
  amount: string
  gateway: {
    type: string
    url: string
  }
}

type ApiResponse = {
  success: boolean
  invoice: ApiResponseInvoice
}

const API_REQUEST_PATH = '/payment/invoice'

export async function paymentInvoicePost(bundle: string, description: string, callbackUrl: string) {
  const response = await useApi<ApiResponse>(API_REQUEST_PATH, 'POST', { bundle, description, callbackUrl })
  if (!response.isOk || !response.data?.success) return
  return response.data.invoice
}
