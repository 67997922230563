type ApiResponse = {
  success: boolean
  status: 'created' | 'processing' | 'success' | 'failure'
}

const API_REQUEST_PATH = '/payment/invoice/status/'

export async function paymentInvoiceStatusGet(invoiceId: string) {
  const response = await useApi<ApiResponse>(API_REQUEST_PATH + invoiceId, 'GET')
  if (!response.isOk || !response.data?.success) return
  return response.data
}
